import { ReactNode } from "react";
import { Tag as BackendTag } from "src/employee/onboarding/types/backend";
import { InputFieldKey } from "src/employee/onboarding/types/form-flow";
import {
  Coding,
  Medication,
  Pharmacy,
  Tag,
} from "src/employee/onboarding/types/prescriptions";
import { ChatActivity, User } from "src/employee/portal/types";

export enum ActivityState {
  COMPLETED = "completed",
  DEFAULT = "default",
  ERROR = "error",
  REQUIRED = "required",
}

export interface Activity {
  code: ActivityCode;
  id: string;
  state: ActivityState;
}

export enum ActivityCode {
  ADD_MORE_DETAILS = "add-more-details",
  MAIL_ORDER_FORM_COMPLETION = "mail-order-form-completion",
  MULTI_STEP_SAVINGS_OPPORTUNITY = "multi-step-savings-opportunity",
}

export enum OnboardingType {
  EMPLOYER = "employer",
  EMPLOYEE = "member",
  MEMBER = "member",
}

export enum Portal {
  DIALOG = "dialog",
  TOOLTIP = "tooltip",
}

export interface ChangeMedicationFilters {
  requiringDrApproval: boolean;
  notRequiringDrApproval: boolean;
}

export interface ChangePharmacyFilters {
  mailOrder: boolean;
  walkIn: boolean;
}

export interface Conversation {
  id: string;
  members: User[];
  messages: ChatActivity[];
  prescriptionId: string;
}

export enum EditConfigTitle {
  ADDRESS = "Personal Info",
  AMOUNT = "Amount",
  CHAT_MESSAGE = "Message",
  CONFIRMATION_CODE = "Confirmation code",
  COVERAGE = "Coverage Info",
  DOB = "Date of Birth",
  EMAIL = "Email",
  EMAIL_CONFIRMATION = "Confirm your email",
  FIRST_NAME = "First Name",
  LAST_NAME = "Last Name",
  MOBILE_PHONE = "Mobile Phone Number",
  PHONE = "Phone Number",
  PREFERRED_EMAIL = "Preferred Email",
  PRIMARY_INSURANCE = "Pharmacy Insurance member ID number",
}

export type EmployeeInformationInputFieldKey =
  | InputFieldKey.ADDRESS_CITY
  | InputFieldKey.ADDRESS_COUNTRY
  | InputFieldKey.ADDRESS_POSTAL_CODE
  | InputFieldKey.ADDRESS_STATE
  | InputFieldKey.ADDRESS_STREET_1
  | InputFieldKey.ADDRESS_STREET_2
  | InputFieldKey.DOB
  | InputFieldKey.EMAIL
  | InputFieldKey.FIRST_NAME
  | InputFieldKey.LAST_NAME
  | InputFieldKey.MEMBER_ID
  | InputFieldKey.PHARMACY_PBM
  | InputFieldKey.PHONE_COUNTRY_CODE
  | InputFieldKey.PHONE_NUMBER
  | InputFieldKey.PRIMARY_INSURANCE;

export enum FieldKind {
  CHECKLIST = "checklist",
  FINAL = "final",
  MULTIPLE_INPUTS = "multipleInputs",
  SELECT_MANY = "selectMany",
  SELECT_MEDICATIONS = "selectMedications",
  SELECT_ONE = "selectOne",
  SELECT_PHARMACY = "selectPharmacy",
  TRANFER_RESULTS = "transferResults",
}

export interface Filters {
  changeMedications: ChangeMedicationFilters;
  changePharmacy: ChangePharmacyFilters;
  pharmacies: Record<string, boolean>;
  useDiscountCard: boolean;
}

export interface FormattedPlaidLink {
  content: string;
  icon: ReactNode;
  id: string;
  linkSessionId: string;
  maxAmount: number;
  title: string;
}

export interface Highlight {
  action?: any;
  content: string;
  icon: ReactNode;
  id?: string;
  showAddButton?: boolean;
  showButton?: boolean;
  title: string;
}

export enum InputFieldLabel {
  ADDRESS_CITY = "City",
  ADDRESS_COUNTRY = "Country",
  ADDRESS_POSTAL_CODE = "ZIP/Postal Code",
  ADDRESS_STATE = "State/Province",
  ADDRESS_STREET_1 = "Street Address",
  ADDRESS_STREET_2 = "Suite/Apt/Floor",
  AMOUNT = "Amount",
  CONFIRMATION_CODE = "Confirmation Code",
  DOB = "Date of Birth",
  EMAIL = "Personal Email",
  FIRST_NAME = "First Name",
  LAST_NAME = "Last Name",
  MEDICAL_PLAN = "Medical Plan",
  MEMBER_ID = "Member ID",
  MOBILE_PHONE_NUMBER = "Mobile Phone Number",
  PHARMACY_PLAN = "Pharmacy Plan",
  PHONE_COUNTRY_CODE = "Country Code",
}

export enum InputFieldPlaceholder {
  ADDRESS_CITY = "",
  ADDRESS_COUNTRY = "Select one",
  ADDRESS_POSTAL_CODE = "",
  ADDRESS_STATE = "Select one",
  ADDRESS_STREET_1 = "",
  ADDRESS_STREET_2 = "",
  AMOUNT = "",
  CHAT_MESSAGE = "Write a message",
  CONFIRMATION_CODE = "######",
  DOB = "mm/dd/yyyy",
  EMAIL = "",
  FIRST_NAME = "",
  FULL_NAME = "",
  LAST_NAME = "",
  MEMBER_ID = "",
  PHARMACY_PBM = "",
  PHONE_COUNTRY_CODE = "1",
  PHONE_NUMBER = "123-456-7890",
  PRIMARY_INSURANCE = "#########",
}

export enum LocalStorage {
  FILTERS = "filters",
  SORT_BY = "sort-by",
}

export interface MediaFile {
  height: number;
  src: string;
  width: number;
}

export enum NotificationLayout {
  MESSAGE = "message",
  NOTIFICATION = "notification",
  THREAD = "thread",
}

export type MedicationChangeCoding =
  | Coding.DAY_SUPPLY_OPTIMIZATION
  | Coding.MEDICATION_CHANGE_BRAND_TO_GENERIC_PATIENT
  | Coding.MEDICATION_CHANGE_BRAND_TO_GENERIC_PRESCRIBER
  | Coding.MEDICATION_CHANGE_COMBO_SPLIT
  | Coding.MEDICATION_CHANGE_FORMULATION_CHANGE
  | Coding.MEDICATION_CHANGE_NO_PRESCRIBER_APPROVAL
  | Coding.MEDICATION_CHANGE_PILL_SPLIT
  | Coding.MEDICATION_CHANGE_PRESCRIBER_APPROVAL
  | Coding.MEDICATION_CHANGE_THERAPEUTIC_INTERCHANGE;

export interface PaymentCard {
  cvv: string;
  expiry: string;
  name: string;
  number: string;
}

export type PharmacyChangeCoding =
  | Coding.PHARMACY_CHANGE_MAIL_ORDER
  | Coding.PHARMACY_CHANGE_RETAIL;

export interface PhysicianInformation {
  addressCity: string;
  addressPostCode: string;
  addressState: string;
  addressStreet1: string;
  dea: string;
  fax: string;
  name: string;
  npi: string;
  phone: string;
}

export interface Prescription {
  activities: Activity[];
  bestMaxAnnualSavings: number;
  bestMaxMonthlySavings: number;
  claim: number | null;
  currentMaxAnnualSavings: number;
  currentMaxMonthlySavings: number;
  id: string;
  isTakingMedication: boolean;
  medication: Medication;
  messages: ChatActivity[];
  pharmacy: Pharmacy;
  prescriberName: string | null;
  prescriptionSwitchRequests: PrescriptionSwitchRequest[];
  selectedPrescriptionSwitchRequest: PrescriptionSwitchRequest | null;
  state: PrescriptionState;
}

export type PrescriptionCard = Pick<
  Prescription,
  | "activities"
  | "bestMaxMonthlySavings"
  | "id"
  | "medication"
  | "messages"
  | "pharmacy"
  | "prescriberName"
  | "selectedPrescriptionSwitchRequest"
  | "state"
>;

export enum PrescriptionOptionCardState {
  DEFAULT = "default",
  DISABLED = "disabled",
  IN_REVIEW = "in-review",
  NEEDS_REVIEW = "needs-review",
  SELECTED = "selected",
}

export enum PrescriptionRecommendationCardtype {
  SAVINGS = "savings",
  PRIOR_AUTHORIZATION = "prior_authorization",
}

export enum PrescriptionState {
  ACTIVE = "active",
  INACTIVE = "inactive",
  NEEDS_REVIEW = "needs-review",
  NEW = "new",
  NO_OPTIONS = "no-options",
}

export interface PrescriptionSwitchRequest {
  annualSavings: number;
  claim: any;
  id: number;
  maxAnnualSavings: number;
  maxMonthlySavings: number;
  prescriberName: string | null;
  tag: Tag;
  user: number;
}

export interface BackendPrescriptionSwitchRequest {
  id: number;
  annual_savings: string;
  user: number;
  claim: number | null;
  max_annual_savings: number;
  max_monthly_savings: number;
  prescriber_name: string | null;
  tag: BackendTag;
}

export type ReverseMap<T> = T[keyof T];

export interface Thread {
  conversation: Conversation;
  id: string;
}

import * as cookie from "cookie";
import * as setCookieParser from "set-cookie-parser";
import { Cookie } from "set-cookie-parser";

// This code is mostly taken from the nookies library:
// https://github.com/maticzav/nookies
// Adapted due to the library not being updated in 4 years.

export interface CookieType {
  getCookie: (key: string) => any;
  setCookie: (key: string, value: any, options?: object) => void;
  deleteCookie: (key: string, options?: object) => void;
}

export default function cookies(context?: any): CookieType {
  const defaultOptions = { path: "/", maxAge: 7 * 24 * 60 * 60 };

  const isBrowser = () => {
    return typeof window !== "undefined";
  };

  type Dict<T = any> = { [key: string]: T };

  const createCookie = (name: string, value: string, options: any): Cookie => {
    let sameSite = options.sameSite;
    if (sameSite === true) {
      sameSite = "strict";
    }
    if (sameSite === undefined || sameSite === false) {
      sameSite = "lax";
    }
    const cookieToSet = { ...options, sameSite: sameSite };
    delete cookieToSet.encode;
    return {
      name: name,
      value: value,
      ...cookieToSet,
    };
  };

  /**
   * Tells whether given objects have the same properties.
   */
  const hasSameProperties = (a: Dict, b: Dict) => {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];

      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    return true;
  };

  /**
   * Compare the cookie and return true if the cookies have equivalent
   * options and the cookies would be overwritten in the browser storage.
   *
   * @param a first Cookie for comparison
   * @param b second Cookie for comparison
   */
  const areCookiesEqual = (a: Cookie, b: Cookie) => {
    let sameSiteSame = a.sameSite === b.sameSite;
    if (typeof a.sameSite === "string" && typeof b.sameSite === "string") {
      sameSiteSame = a.sameSite.toLowerCase() === b.sameSite.toLowerCase();
    }

    return (
      hasSameProperties(
        { ...a, sameSite: undefined },
        { ...b, sameSite: undefined },
      ) && sameSiteSame
    );
  };

  const parseCookies = (options = {}) => {
    if (context?.req?.headers?.cookie) {
      return cookie.parse(context.req.headers.cookie as string, options);
    }
    if (isBrowser()) {
      return cookie.parse(document.cookie, options);
    }
    return {};
  };

  const getCookie = (key: string) => {
    return parseCookies(defaultOptions)[key];
  };

  const setCookie = (
    name: string,
    value: any,
    options: cookie.SerializeOptions = {},
  ) => {
    options = { ...defaultOptions, ...options };

    // SSR
    if (context?.res?.getHeader && context.res.setHeader) {
      // Check if response has finished and warn about it.
      if (context?.res?.finished) {
        console.warn(`Not setting "${name}" cookie. Response has finished.`);
        console.warn(`You should set cookie before res.send()`);
        return {};
      }

      /**
       * Load existing cookies from the header and parse them.
       */
      let cookies = context.res.getHeader("Set-Cookie") || [];

      if (typeof cookies === "string") cookies = [cookies];
      if (typeof cookies === "number") cookies = [];

      /**
       * Parse cookies but ignore values - we've already encoded
       * them in the previous call.
       */
      const parsedCookies = setCookieParser.parse(cookies, {
        decodeValues: false,
      });

      /**
       * We create the new cookie and make sure that none of
       * the existing cookies match it.
       */
      const newCookie = createCookie(name, value, options);
      let cookiesToSet: string[] = [];

      parsedCookies.forEach((parsedCookie: Cookie) => {
        if (!areCookiesEqual(parsedCookie, newCookie)) {
          /**
           * We serialize the cookie back to the original format
           * if it isn't the same as the new one.
           */
          const serializedCookie = cookie.serialize(
            parsedCookie.name,
            parsedCookie.value,
            {
              // we prevent reencoding by default, but you might override it
              encode: (val: string) => val,
              ...(parsedCookie as cookie.SerializeOptions),
            },
          );

          cookiesToSet.push(serializedCookie);
        }
      });
      cookiesToSet.push(cookie.serialize(name, value, options));

      // Update the header.
      context.res.setHeader("Set-Cookie", cookiesToSet);
    }

    // Browser
    if (isBrowser()) {
      if (options && options.httpOnly) {
        throw new Error("Can not set a httpOnly cookie in the browser.");
      }

      document.cookie = cookie.serialize(name, value, options);
    }

    return {};
  };

  const deleteCookie = (name: string, options = {}) => {
    options = { ...defaultOptions, maxAge: 0, ...options };
    return setCookie(name, "", { ...(options || {}), maxAge: -1 });
  };

  return {
    getCookie,
    setCookie,
    deleteCookie,
  };
}

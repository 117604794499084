import { Activity } from "src/employee/activity/config/activity";
import { MedicalHistoryFlowKey } from "src/employee/activity/medical-history/types";

export const ROUTES = {
  ADMIN: "/admin",
  CONTACT_US: "/contact-us",
  EMPLOYEE: {
    ACTIVITIES: {
      MEDICAL_HISTORY: {
        AUTHORIZATION: `/employee/activity/${Activity.MEDICAL_HISTORY}/${MedicalHistoryFlowKey.AUTHORIZATION}`,
        DONE: `/employee/activity/${Activity.MEDICAL_HISTORY}/${MedicalHistoryFlowKey.DONE}`,
        DRUG_ALLERGIES: `/employee/activity/${Activity.MEDICAL_HISTORY}/${MedicalHistoryFlowKey.DRUG_ALLERGIES}`,
        HOSPITALIZATIONS: `/employee/activity/${Activity.MEDICAL_HISTORY}/${MedicalHistoryFlowKey.HOSPITALIZATIONS}`,
        MEDICAL_CONDITIONS: `/employee/activity/${Activity.MEDICAL_HISTORY}/${MedicalHistoryFlowKey.MEDICAL_CONDITIONS}`,
        MEDICAL_HISTORY: `/employee/activity/${Activity.MEDICAL_HISTORY}/${MedicalHistoryFlowKey.MEDICAL_HISTORY}`,
        OPERATIONS: `/employee/activity/${Activity.MEDICAL_HISTORY}/${MedicalHistoryFlowKey.OPERATIONS}`,
        OTHER_MEDICATIONS: `/employee/activity/${Activity.MEDICAL_HISTORY}/${MedicalHistoryFlowKey.OTHER_MEDICATIONS}`,
        PRESCRIPTION_PREFERENCE: `/employee/activity/${Activity.MEDICAL_HISTORY}/${MedicalHistoryFlowKey.PRESCRIPTION_PREFERENCES}`,
        SIGN: `/employee/activity/${Activity.MEDICAL_HISTORY}/${MedicalHistoryFlowKey.SIGN}`,
        START: `/employee/activity/${Activity.MEDICAL_HISTORY}/${MedicalHistoryFlowKey.START}`,
        UPLOAD_ID: `/employee/activity/${Activity.MEDICAL_HISTORY}/${MedicalHistoryFlowKey.UPLOAD_ID}`,
      },
    },
    LOGIN: "/login",
    ONBOARDING: {
      ACTIVATE_YOUR_ACCOUNT: "/signup/activate",
      LOADING_INSURANCE: "/signup/loading-insurance",
      AUTHORIZATION: "/signup/authorization",
      CONFIRM_EMAIL: "/signup/confirm-email",
      CONFIRM_MOBILE_PHONE: "/signup/confirm-mobile-phone",
      CONFIRM_YOUR_DETAILS: "/signup/confirm-your-details",
      DONE: "/signup/done",
      EMAIL: "/signup/email",
      LOGIN: "/login",
      MOBILE_PHONE: "/signup/mobile-phone",
      QUICK_SET_UP: "/employee/onboarding/quick-set-up",
      REVIEW_MEDICATIONS: "/signup/review-medications",
      REVIEW_PRESCRIPTION_UPDATES: "/signup/review-changes",
      ROOT: "/signup",
      SAVINGS_PREVIEW: "/signup/savings-preview",
      UPDATED_REPORT: "/signup/savings-opportunities",
      WELCOME_UNAUTHENTICATED: "/signup",
    },
    PORTAL: {
      ACCOUNT: {
        FILTERS: "/dashboard/account/filters",
        PAYMENT: {
          ROOT: "/dashboard/account/payment",
          WITHDRAWAL: {
            EDIT_ADDRESS: "/dashboard/account/payment/withdrawal/edit-address",
            INITIATED: "/dashboard/account/payment/withdrawal-initiated",
            ROOT: "/dashboard/account/payment/withdrawal",
          },
        },
        MY_ACCOUNT: "/dashboard/account",
      },
      CHAT: "/dashboard/chat",
      LOG_IN: "/login",
      SHARE_YOUR_BENEFIT: "/dashboard/share-your-benefit",
      MESSAGE: "/dashboard/messages/[id]",
      MESSAGES: "/dashboard/messages",
      PHOTO_ID: "/dashboard/photo-id",
      PRESCRIPTION: {
        CONFIRMATION: "/dashboard/prescription/[id]/confirmation",
        OPTIONS: "/dashboard/prescription/[id]/options",
        REVIEW: "/dashboard/prescription/[id]/review",
        ROOT: "/dashboard/prescription/[id]",
      },
      ROOT: "/dashboard",
      WALLET: "/dashboard/wallet",
      REVIEW_OPPORTUNITIES: "/dashboard/review-opportunities",
      CONFIRM_CHANGES: "/dashboard/review-opportunities/confirm-changes",
    },
    SIGN_OUT: "/employee/sign-out",
  },
  FAQ: "/faq",
  HOME: "/",
  LANDING: {
    CONTACT_US: "/contact-us",
    PRIVACY_POLICY: "/privacy-policy",
    TERMS_OF_USE: "/terms-of-use",
  },
  PORTAL: "/portal",
  SHARE_BENEFIT: "/adding-family-members-form",
  WAITLIST: "/waitlist",
  AC_CONTACT_US: "https://discover.counterhealth.com/contact-us",
  AC_SHARE_BENEFIT:
    "https://discover.counterhealth.com/adding-family-members-form",
};
